import styled from "styled-components";
import { LAPTOP_MOBILE_POINTER } from '@tools/constants';
import { Swiper } from "swiper/react";

export const SliderArrows = styled.div`
  display: none;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    display: flex;
    align-self: center;
    gap: 20px;

    height: 48px;
  }
`;

export const SliderArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0px 0px 12px 0px #616A881A;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background: var(--background-main-white);

  transition: all 0.3s;

  cursor: pointer;
  user-select: none;

  &.swiper-button-disabled {
    pointer-events: none;
    cursor: auto;
    opacity: 0.7;
    box-shadow: unset;
  }
`

export const SliderArrowPrev = styled(SliderArrow)`
  transform: rotate(180deg);
`

export const SliderWrapper = styled.div`
  position: relative;

  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100vw;
  overflow: hidden;

  @media (min-width: 609px) {
    padding-left: calc(calc(100vw - 587px) / 2);
    padding-right: calc(calc(100vw - 587px) / 2);
  }

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    padding-left: calc(calc(100vw - 1144px) / 2);
    padding-right: calc(calc(100vw - 1144px) / 2);
  }
`

export const SliderSwiper = styled(Swiper)`
  .swiper-wrapper {
    max-height: 100%;
    height: 100%;
    display: inline-flex;
  }
`;

export const SliderPagination = styled.div`
  --point-height: 3px;

  position: absolute;
  bottom: var(--point-height);
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    display: none;
  }

  span {
    margin: 0 5px;
  }

  .swiper-pagination-bullet {
    transition: all 0.3s ease;
    width: 22px;
    height: var(--point-height);
    background: #E5F2FF;
    border-radius: 5px;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #A9C3E4;
  }
`;
