import React from 'react';
import styled from 'styled-components';
import { ButtonUI, TypographyUI } from "@ui";
import { AdaptiveLinkCt } from "@components";
import { LAPTOP_MOBILE_POINTER } from '@tools/constants';
import cardDecor from '@images/career/why-choose-us-card-bg-decor.svg';
import cardBgHealthCare from '@images/career/why-choose-us-card-bg-health-care.webp';
import cardBgOpenCulture from '@images/career/why-choose-us-card-bg-open-culture.webp';
import cardBgLocation from '@images/career/why-choose-us-card-bg-location.webp';
import cardBgSalary from '@images/career/why-choose-us-card-bg-salary.webp';
import cardBgLife from '@images/career/why-choose-us-card-bg-life.webp';
import cardBgMember from '@images/career/why-choose-us-card-bg-member.webp';
import cardBgPartner from '@images/career/why-choose-us-card-bg-partner.webp';
import cardBgSkolkovo from '@images/career/why-choose-us-card-bg-skolkovo.webp';

const WhyChooseUsCardHealthCare = () => (
  <CardHealthCare>
    <TypographyUI.H4Bold>Забота о здоровье</TypographyUI.H4Bold>

    <TypographyUI.Body1Regular>Оформляем ДМС со стоматологией по всей России</TypographyUI.Body1Regular>
  </CardHealthCare>
)

const WhyChooseUsCardOpenCulture = () => (
  <CardOpenCulture>
    <TypographyUI.H4Bold>Открытая культура</TypographyUI.H4Bold>
    <TypographyUI.Body1Regular>Формируем среду для развития вашего потенциала, где каждый будет услышан. Не любим бюрократию и не практикуем микроменеджмент</TypographyUI.Body1Regular>
  </CardOpenCulture>
)

const WhyChooseUsCardLocation = () => (
  <CardLocation>
    <TypographyUI.H4Bold>Офис в центре Москвы</TypographyUI.H4Bold>
    <TypographyUI.Body1Regular>Уютный open space в трех минутах от Красной площади. Помогаем обустроить рабочее место и даем каждому новую технику Apple</TypographyUI.Body1Regular>
  </CardLocation>
)

const WhyChooseUsCardSalary = () => (
  <CardSalary>
    <TypographyUI.H4Bold>Конкурентная зарплата</TypographyUI.H4Bold>
    <TypographyUI.Body1Regular>Следим за изменениями на рынке труда и платим достойно</TypographyUI.Body1Regular>
  </CardSalary>
)

const WhyChooseUsCardLife = () => (
  <CardLife>
    <TypographyUI.H4Bold>Корпоративная жизнь</TypographyUI.H4Bold>
    <TypographyUI.Body1Regular>Команда — наше все! Организуем корпоративы и активности каждый месяц: катаемся на сапбордах, играем в покер и ходим в бары по пятницам </TypographyUI.Body1Regular>
  </CardLife>
)

const WhyChooseUsCardMember = () => (
  <CardMember>
    <CardMemberTitle>Участники реестра аккредитованных <TextAccent>IT-компаний</TextAccent></CardMemberTitle>
  </CardMember>
)

const WhyChooseUsCardPartner = () => (
  <CardPartner>
    <TypographyUI.H4Bold>Официальные партнеры ФНС</TypographyUI.H4Bold>
  </CardPartner>
)

const WhyChooseUsCardSkolkovo = () => (
  <CardSkolkovo>
    <TypographyUI.H4Bold>Резиденты Сколково</TypographyUI.H4Bold>
  </CardSkolkovo>
)

export const CareerWhyChooseUs = () => (
  <Section>
    <Header>
      <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER}>Почему выбирают нас</TypographyUI.H2>

      <TypographyUI.Body1Regular>ценим каждого члена команды и открыты новым идеям</TypographyUI.Body1Regular>
    </Header>

    <List>
      <WhyChooseUsCardHealthCare />

      <WhyChooseUsCardOpenCulture />

      <WhyChooseUsCardLocation />

      <WhyChooseUsCardSalary />

      <WhyChooseUsCardLife />

      <WhyChooseUsCardMember />

      <WhyChooseUsCardPartner />

      <WhyChooseUsCardSkolkovo />
    </List>

    <Footer>
      <AdaptiveLinkCt to="https://hh.ru/employer/4152303">
        <ButtonUI>Смотреть вакансии</ButtonUI>
      </AdaptiveLinkCt>
    </Footer>
  </Section>
)

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    gap: 3.75rem;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-left: 1rem;
    padding-right: 1rem;

    text-align: center;
  }

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    gap: 1.5rem;
  }
`

const List = styled.div`
  display: grid;
  gap: 1.25rem;
  grid-template:
    'healthCare'
    'openCulture'
    'location'
    'salary'
    'life'
    'member'
    'partner'
    'skolkovo';

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    grid-template:
    'healthCare location salary'
    'openCulture location life'
    'member partner skolkovo';
  }
`


const Footer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
`

const Card = styled.div`
  --padding: 1.25rem;
  --bg-size: 5rem;
  --gap: 1.5rem;

  display: flex;
  flex-direction: column;
  gap: 1.25rem;

  border-radius: 1.25rem;
  padding: calc(var(--padding) + var(--bg-size) + var(--gap)) 2rem 1.25rem;
  background-color: var(--background-second, #E5F2FF);
  background-repeat: no-repeat;
  background-position: 1.25rem 1.25rem;
  background-size: 80px;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    --padding: 2rem;
    --bg-size: 5rem;
    --gap: 1.25rem;
  }
`

const CardHealthCare = styled(Card)`
  grid-area: healthCare;

  background-image: url(${cardBgHealthCare});
`
const CardOpenCulture = styled(Card)`
  grid-area: openCulture;

  background-image: url(${cardBgOpenCulture});
`
const CardLocation = styled(Card)`
  grid-area: location;

  padding-top: 1.25rem;
  padding-bottom: 8.6rem;

  background-color: #18C4BE;
  background-image: url(${cardBgLocation}), url(${cardDecor});
  background-repeat: no-repeat;
  background-position: calc(100% - var(--padding)) calc(100% - var(--padding)), center 60%;
  background-size: 95px 116px, 100% 100%;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    background-position: calc(100% - var(--padding) - 1rem) calc(100% - var(--padding) - 1rem), center 50%;
    background-size: 180px 222px, 100% 100%;
  }
`
const CardSalary = styled(Card)`
  grid-area: salary;

  background-image: url(${cardBgSalary});
`
const CardLife = styled(Card)`
  grid-area: life;

  background-image: url(${cardBgLife});
`
const CardMember = styled(Card)`
  grid-area: member;

  padding-bottom: 3.5rem;
  background-color: var(--accent-main, #007AFF);
  background-image: url(${cardBgMember});
`
const CardMemberTitle = styled(TypographyUI.H4Bold)`
  color: var(--background-main-grey, #F5F8FB);
`

const CardPartner = styled(Card)`
  grid-area: partner;

  padding-bottom: 3.5rem;
  background-image: url(${cardBgPartner});
`
const CardSkolkovo = styled(Card)`
  grid-area: skolkovo;

  padding-bottom: 3.5rem;
  background-image: url(${cardBgSkolkovo});
`

const TextAccent = styled.span`
  color: #18C4BE;
`
