import React from "react";
import styled from "styled-components";
import { TypographyUI } from "@ui";
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import img from '@images/career/banner-company.webp';

export const CareerBannerCompany = () => (
  <Section>
    <Text>
      Не боимся новых процессов и двигаемся <TextAccent>вместе</TextAccent> с трендами
      на рынке <TextAccent>IT</TextAccent>
    </Text>
  </Section>
)

const Section = styled.section`
  padding: 84px 16px;
  height: 240px;
  border-radius: 1rem;

  background-image: url(${img});
  background-size: cover;
  background-position: center 40%;
  background-repeat: no-repeat;
  overflow: hidden;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    padding: 202px 298px;
    height: 547px;
  }
`

const Text = styled(TypographyUI.H4Bold)`
  color: #fff;
  text-align: center;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
  }
`

const TextAccent = styled.span`
  color: var(--accent-main, #007aff);
`
