import React, {useEffect} from "react";
import styled from "styled-components";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";

export const CareerFormSection = () => {

    useEffect(() => {
        const frame: any = document.getElementById('careerForm');
        frame.src = "https://forms.yandex.ru/cloud/67375c9c4936392b39f71555/?iframe=1";
        frame.name = 'ya-form-67375c9c4936392b39f71555';
        frame.addEventListener('load', () => {
            try {
                frame.style.height = frame.contentWindow.document.documentElement.scrollHeight + 'px';
            } catch (e) {
                frame.style.height = '660px';
            }
        })
        window.addEventListener('resize', () => {
            try {
                frame.style.height = frame.contentWindow.document.documentElement.scrollHeight + 'px';
            } catch (e) {
                frame.style.height = '660px';
            }
        })
    }, []);

    useEffect(() => {
        const formListener = (e) => {
        };
        window.addEventListener('message', formListener);
        return () => {
            window.removeEventListener('message', formListener);
        };
    }, []);

    return <CareerForm>
        <script src="https://forms.yandex.ru/_static/embed.js"></script>
        <iframe id="careerForm" style={{width: '100%', border: 'unset'}}></iframe>
    </CareerForm>

}

const CareerForm = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  align-items: center;
  position: relative;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 20px;
  }
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    padding-bottom: 20px;
  }
`;