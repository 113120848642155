import React, { useRef } from "react";
import styled from "styled-components";
import { LAPTOP_MOBILE_POINTER } from '@tools/constants';
import { TypographyUI } from "@ui";
import { useWindowSize } from "@tools/hooks";
import { Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
import {
  SliderArrows,
  SliderArrow,
  SliderArrowPrev,
  SliderWrapper,
  SliderPagination,
  SliderSwiper
} from './CareerSlider';

import CardBg1 from '@images/career/what-we-do-1.webp'
import CardBg2 from '@images/career/what-we-do-2.webp'
import CardBg3 from '@images/career/what-we-do-3.webp'
import CardBg4 from '@images/career/what-we-do-4.webp'
import CardBg5 from '@images/career/what-we-do-5.webp'
import srcArrowRight from "@images/shared/arrow-right-grey.svg";

const list = [
  {
    title: 'Массовые выплаты исполнителям',
    description: 'Помогаем компаниям проводить выплаты любому количеству внештатных исполнителей',
    img: CardBg1,
  },
  {
    title: 'ЭДО',
    description: 'Автоматизируем документооборот между заказчиком и исполнителями',
    img: CardBg2,
  },
  {
    title: 'Интернет-эквайринг',
    description: 'Подключаем эквайринг на сайте и в соцсетях для физических лиц',
    img: CardBg3,
  },
  {
    title: 'Маркетплейс для поиска заказов',
    description: 'Помогаем исполнителям находить заказы по всей России, а компаниям — специалистов под конкретные задачи',
    img: CardBg4,
  },
  {
    title: 'Электронный кошелек',
    description: 'Предоставляем Кошелек для физлиц — инструмент для приема платежей и финансовой аналитики доходов',
    img: CardBg5,
  },
]

export const CareerWhatWeDo = () => {
  const { width } = useWindowSize();

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const paginationRef = useRef(null);

  const slidesPerView = (windowWidth: number) => {
    if (windowWidth > LAPTOP_MOBILE_POINTER) {
      return 1.8
    }

    if (windowWidth >= 609) {
      return 1.3
    }

    return 1.05;
  }

  const spaceBetween = (windowWidth: number) => {
    if (windowWidth > LAPTOP_MOBILE_POINTER) {
      return 20;
    }

    return 12;
  }

  return (
    <Section>
      <Header>
        <HeaderTitle>
          <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER}>Что мы делаем</TypographyUI.H2>

          <TypographyUI.Body1Regular>Создаем экосистему продуктов, в которой взаимодействие с внештатными исполнителями становится удобным и безопасным</TypographyUI.Body1Regular>
        </HeaderTitle>

        <SliderArrows>
          <SliderArrowPrev ref={navigationPrevRef}>
            <img src={srcArrowRight} alt="" />
          </SliderArrowPrev>

          <SliderArrow ref={navigationNextRef}>
            <img src={srcArrowRight} alt="" />
          </SliderArrow>
        </SliderArrows>
      </Header>

      <SliderWrapper>
        <SliderSwiper
          modules={[Navigation, Pagination]}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            (swiper.params.navigation as any).prevEl = navigationPrevRef.current;
            (swiper.params.navigation as any).nextEl = navigationNextRef.current;
            (swiper.params.pagination as any).el = paginationRef.current;
          }}
          pagination={{
            el: paginationRef.current,
            clickable: true,
          }}
          slidesPerView={slidesPerView(width)}
          spaceBetween={spaceBetween(width)}
        >

          {list.map(({ title, description, img }) => (
            <SwiperSlide key={title}>
              <Card bg={img}>
                <CardTitle>{title}</CardTitle>

                <CardSubtitle>{description}</CardSubtitle>
              </Card>
            </SwiperSlide>
          ))}
        </SliderSwiper>

        <SliderPagination ref={paginationRef} />
      </SliderWrapper>
    </Section>
  );
}

const Section = styled.section`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    gap: 3.75rem;
  }
`

const Header = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    justify-content: space-between;
    gap: 200px;
  }
`

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  width: 100%;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    max-width: 610px;
  }
`

const Card = styled.div<{ bg: string }>`
  --padding: 1.5rem;
  --image-size-height: 215px;
  --image-size-width: 315px;

  position: relative;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;

  border-radius: 1.25rem;
  padding: var(--padding) var(--padding) calc(var(--image-size-height) + var(--padding));
  height: 401px;

  background-color: var(--background-main-grey, #F5F8FB);
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-size: auto var(--image-size-height);
  background-position: center bottom;
  overflow: hidden;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    --image-size: 215px;

    padding-right: var(--image-size-width);
    background-size: var(--image-size-width) auto;
    background-position: calc(100% - var(--padding)) center;
  }

  @media (min-width: 609px) and (max-width: ${LAPTOP_MOBILE_POINTER - 1}px) {

  }
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {

  }
`

const CardTitle = styled(TypographyUI.H4Bold)`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    font-size: 1.75rem;
    line-height: 2.25rem;
  }
`

const CardSubtitle = styled(TypographyUI.Body1Regular)`
  color: var(--text-secondary, #616A88);
`
