import React, { FC, useRef } from "react";
import styled from "styled-components";
import { TypographyUI } from "@ui";
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { useWindowSize } from "@tools/hooks";
import { Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
import {
  SliderWrapper,
  SliderPagination,
  SliderSwiper
} from './CareerSlider';

import img1 from '@images/career/we-help-1.webp';
import img2 from '@images/career/we-help-2.webp';
import img3 from '@images/career/we-help-3.webp';

const list = [
  {
    title: '5 лет',
    subtitle: 'с 2019 года прошли путь от стартапа до экосистемы продуктов',
    img: img1,
  },
  {
    title: '400 000',
    subtitle: 'клиентов пользуются нашими сервисами',
    img: img2,
  },
  {
    title: '60 млрд',
    subtitle: 'рублей оборота физических и юридических лиц прошло через наш сервис',
    img: img3,
  },
]

export const CareerWeHelp = () => {
  const { width } = useWindowSize();

  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const paginationRef = useRef(null);

  const slidesPerView = (windowWidth: number) => {
    if (windowWidth > LAPTOP_MOBILE_POINTER) {
      return 1.8
    }

    if (windowWidth >= 609) {
      return 1.3
    }

    return 1.05;
  }

  const spaceBetween = (windowWidth: number) => {
    if (windowWidth > LAPTOP_MOBILE_POINTER) {
      return 20;
    }

    return 12;
  }

  return (
    <Section>
      <Header>
        <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER}>Мы помогаем тысячам компаний <TextAccent>автоматизировать</TextAccent> работу с исполнителями <wbr /> в рамках закона:</TypographyUI.H2>

        <TypographyUI.Body1Regular>от поиска специалиста до взаимодействия с государственными органами</TypographyUI.Body1Regular>
      </Header>

      <ListDesktop>
        {list.map(({ title, subtitle, img }, index) => (
          <Card
            key={`${title}_${index}`}
            bg={img}
          >
            <CardContent>
              <TypographyUI.H2>{title}</TypographyUI.H2>

              <CardSubtitle>{subtitle}</CardSubtitle>
            </CardContent>
          </Card>
        ))}
      </ListDesktop>

      <ListMobile>
        <SliderWrapper>
          <SliderSwiper
            modules={[Navigation, Pagination]}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={(swiper) => {
              (swiper.params.navigation as any).prevEl = navigationPrevRef.current;
              (swiper.params.navigation as any).nextEl = navigationNextRef.current;
              (swiper.params.pagination as any).el = paginationRef.current;
            }}
            pagination={{
              el: paginationRef.current,
              clickable: true,
            }}
            slidesPerView={slidesPerView(width)}
            spaceBetween={spaceBetween(width)}
          >

            {list.map(({ title, subtitle, img }, index) => (
              <SwiperSlide key={title}>
                <Card
                  key={`${title}_${index}`}
                  bg={img}
                >
                  <CardContent>
                    <TypographyUI.H2>{title}</TypographyUI.H2>

                    <CardSubtitle>{subtitle}</CardSubtitle>
                  </CardContent>
                </Card>
              </SwiperSlide>
            ))}
          </SliderSwiper>

          <SliderPagination ref={paginationRef} />
        </SliderWrapper>
      </ListMobile>
    </Section>
  );
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    display: grid;
    grid-template:
    'header ListDesktop';
    align-items: flex-start;
    grid-template-columns: 439px 1fr;
    gap: 50px;
  }
`;

const Header = styled.div`
  grid-area: header;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const TextAccent = styled.span`
  color: var(--accent-main, #007aff);
`

const ListDesktop = styled.div`
  grid-area: ListDesktop;

  display: none;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
`;

const ListMobile = styled.div`
  grid-area: ListMobile;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    display: none;
  }
`;

const Card = styled.div<{ bg: string }>`
  --background-size: 70px;
  --padding: 24px;

  border-radius: 1.25rem;
  padding: var(--padding);
  height: 190px;

  background-color: var(--background-second, #E5F2FF);
  background-image: url(${({ bg }) => bg});
  background-repeat: no-repeat;
  background-position: calc(100% - var(--padding)) var(--padding);
  background-size: var(--background-size);

  overflow: hidden;

  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-right: calc(var(--background-size) + var(--padding) + 0.15rem);
  }

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    --background-size: 90px;
    --padding: 32px;

    min-height: 196px;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    gap: 1rem;

    max-width: 414px;
  }
`;

const CardSubtitle = styled(TypographyUI.Body1Regular)`
  color: var(--text-secondary, #616A88);

  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    font-size: 1rem;
    line-height: 1.375rem;
  }
`;
