import React, { ReactNode, FC } from "react"
import styled from "styled-components"
import { LAPTOP_MOBILE_POINTER, MOBILE_MINIMOBILE_POINTER } from "@tools/constants"

type Props = {
    children?: ReactNode;
    disableWidth?: boolean;
};

export const CareerWrapper: FC<Props> = ({ children, disableWidth = false }) => (
    <MainSd className={disableWidth ? 'disableWidth' : ''}>
        {children}
    </MainSd>
)

const MainSd = styled.main`
    height: 100%;
    width: 100%;
    margin: 0 auto;
    flex-grow: 1;

    padding-left: 16px;
    padding-right: 16px;

    @media (min-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 1176px;
    }
    @media (min-width: ${MOBILE_MINIMOBILE_POINTER}px) and (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        max-width: 619px;
    }
    @media (max-width: ${MOBILE_MINIMOBILE_POINTER}px) {
        max-width: 375px;
    }

    &.disableWidth {
        @media (min-width: ${LAPTOP_MOBILE_POINTER}px) {
            max-width: unset;
            padding: 0;
        }
        @media (min-width: ${MOBILE_MINIMOBILE_POINTER}px) and (max-width: ${LAPTOP_MOBILE_POINTER}px) {
            max-width: unset;
            padding: 0;
        }
        @media (max-width: ${MOBILE_MINIMOBILE_POINTER}px) {
            max-width: unset;
            padding: 0;
        }
    }
`
